import LogoWhite from '../../images/logo-white.svg';

const darkTheme = {
  isDark: true,
  Logo: LogoWhite,
  primary: '#58e9ba',
  secondary: '#262626',
  tertiary: '#4236cf',
  bg: 'rgba(22, 22, 22, 0.9)',
  bg2: '#0A0A0A',
  nav: 'rgba(32, 32, 32, 0.95)',
  titleText: '#fff',
  bodyText: '#2d2d2d',
  primaryButtonText: '#fff',
  secondaryButtonText: '#161616',
  primaryBorder: 'rgba(66, 54, 207, 0.28)',
  secondaryBorder: 'rgba(88, 233, 186, 0.28)',
  badgeBorder: 'rgba(66, 54, 207, 0.09)',
  boxShadow1: 'rgba(66, 54, 207, 0.23)',
  boxShadow2: 'rgba(66, 54, 207, 0.16)',
  boxShadow3: 'rgba(88, 233, 186, 0.23)',
  success: '#58e9ba',
  grey1: 'rgba(22, 22, 22, 0.3)',
  grey2: '#333',
  grey3: 'rgba(91, 91, 91, 0.3)',
  gradient1: '#58e9ba',
  gradient2: '#262626',
  gradient3: '#4236cf',
  gradient4: '#161616',
};

export default darkTheme;
