import React from 'react';
import ReactDOM from 'react-dom/client';

import '/src/styles/global.css';
import { ThemeWrapper } from '/src/context/ThemeContext';
import GradientAnimation from '/src/components/GradientAnimation';

export const wrapPageElement = ({ element }) => (
  <ThemeWrapper>
    <GradientAnimation />
    {element}
  </ThemeWrapper>
);

export const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container)
    root.render(element)
  }
}
