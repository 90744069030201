import React, {
  memo,
  useState,
} from 'react';

import styled from 'styled-components';
import { Script } from 'gatsby';

import darkTheme from '../styles/themes/darkTheme';
import lightTheme from '../styles/themes/lightTheme';

const Canvas = styled.canvas`
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: -1;
`;

const DarkCanvas = styled(Canvas)`
  display: ${props => props.theme.isDark ? 'block' : 'none'};
  --gradient-color-1: ${darkTheme.gradient1};
  --gradient-color-2: ${darkTheme.gradient2};
  --gradient-color-3: ${darkTheme.gradient3};
  --gradient-color-4: ${darkTheme.gradient4};
`;

const LightCanvas = styled(Canvas)`
  display: ${props => !props.theme.isDark ? 'block' : 'none'};
  --gradient-color-1: ${lightTheme.gradient1};
  --gradient-color-2: ${lightTheme.gradient2};
  --gradient-color-3: ${lightTheme.gradient3};
  --gradient-color-4: ${lightTheme.gradient4};
`;

function GradientAnimation() {
  const [loadedGradientScript, setLoadedGradientScript] = useState(false);

  return (
    <>
      <Script src="/scripts/gradient.js" onLoad={() => void setLoadedGradientScript(true)} />
      {loadedGradientScript && (
        <>
          <Script id="gradient-anim-dark-init">
            {`
              const gradientDark = new Gradient();
              gradientDark.initGradient('#gradient-canvas-dark');
            `}
          </Script>
          <Script id="gradient-anim-light-init">
            {`
              const gradientLight = new Gradient();
              gradientLight.initGradient('#gradient-canvas-light');
            `}
          </Script>
          <DarkCanvas
            id="gradient-canvas-dark"
          />
          <LightCanvas
            id="gradient-canvas-light"
          />
        </>
      )}
    </>
  );
}

export default memo(GradientAnimation);
