import Logo from '../../images/logo.svg';

const lightTheme = {
  isDark: false,
  Logo,
  primary: '#4236cf',
  secondary: '#f9f9ff',
  tertiary: '#4236cf',
  bg: 'rgba(255, 255, 255, 0.7)',
  bg2: '#FFF',
  nav: 'rgba(255, 255, 255, 0.95)',
  titleText: '#000',
  bodyText: '#2d2d2d',
  primaryButtonText: '#fff',
  secondaryButtonText: '#fff',
  primaryBorder: 'rgba(66, 54, 207, 0.28)',
  secondaryBorder: 'rgba(66, 54, 207, 0.28)',
  badgeBorder: 'rgba(66, 54, 207, 0.09)',
  boxShadow1: 'rgba(66, 54, 207, 0.23)',
  boxShadow2: 'rgba(66, 54, 207, 0.16)',
  boxShadow3: 'rgba(66, 54, 207, 0.23)',
  success: '#58e9ba',
  grey1: 'rgba(217, 217, 217, 0.3)',
  grey2: '#e8e8e8',
  grey3: 'rgba(207, 207, 220, 0.3)',
  gradient1: '#4236cf',
  gradient2: '#f9f9ff',
  gradient3: '#58e9ba',
  gradient4: '#fff',
};

export default lightTheme;
