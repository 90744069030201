exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-discussion-forum-js": () => import("./../../../src/pages/discussion-forum.js" /* webpackChunkName: "component---src-pages-discussion-forum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-knowledge-base-js": () => import("./../../../src/pages/knowledge-base.js" /* webpackChunkName: "component---src-pages-knowledge-base-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-pages-voting-poll-js": () => import("./../../../src/pages/voting-poll.js" /* webpackChunkName: "component---src-pages-voting-poll-js" */)
}

