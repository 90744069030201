import React, {
  createContext,
  useState,
} from 'react';

import { ThemeProvider } from 'styled-components';

import darkTheme from '../styles/themes/darkTheme';
import lightTheme from '../styles/themes/lightTheme';

const IS_BROWSER = typeof document !== 'undefined';

const ThemeContext = createContext({
  theme: 'light',
  setTheme: () => {
  },
});

const getTheme = () => {
  if (!IS_BROWSER) {
    return 'light';
  }
  for (let string of document.cookie.split(';')) {
    const [k, v] = string.split('=');
    if (k.trim() === 'theme') {
      return v.trim();
    }
  }
  return window.matchMedia('(prefers-color-scheme: dark)').matches === true ? 'dark' : 'light';
};

export function ThemeWrapper({ children }) {
  const [theme, setTheme] = useState(getTheme());

  return (
    <ThemeContext.Provider
      value={{
        theme,
        setTheme: (val) => {
          document.cookie = `theme=${val};`;
          setTheme(val);
        },
      }}
    >
      <ThemeProvider
        theme={theme === 'dark' ? darkTheme : lightTheme}
      >
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
}

export default ThemeContext;
